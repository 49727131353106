import React, { FC, useRef } from 'react';
import colors from '../../themes/main-colors';
import PgeVideoPlayer from '../pge-video-player';
import ImageLink from '../image-link';
import { useComponentSize } from '../../util/style-utils';
import { GetApp } from '@mui/icons-material';
import { useTranslation } from '../../hooks/useTranslation';
import { Button, useTheme } from '@mui/material';
import PgeButton from '../pge-button/_PgeButton';

interface PromoItemProps {
  layoutType?: string;
  mediaURL?: string;
  mediaType?: string;
  header?: string;
  description?: React.ReactElement;
  link?: string;
  readonly imageLink?: Queries.ModulePromoItemFragment['imageLink'];
  readonly imagePosition?: Ctfl.Field['Position'];
  vimeoDownloadUrl?: string;
}

const PromoItem: FC<PromoItemProps> = ({
  layoutType = 'vertical',
  header = '',
  description = '',
  mediaURL = '',
  mediaType = '',
  link = '',
  imageLink,
  vimeoDownloadUrl,
  imagePosition,
}) => {
  const videoDivRef = useRef<HTMLDivElement>(null);
  const { width } = useComponentSize(videoDivRef);
  const { t } = useTranslation();

  const vimeoProps = /vimeo.com/gi.test(mediaURL)
    ? { width: `${width}px`, height: `${(width / 16) * 9}px` }
    : {};

  const theme = useTheme()

  return (
    <div
      css={layoutType === 'vertical'
        ? {
          display: 'block',
          backgroundColor: colors.white,
          boxShadow: '0px 3px 6px #00002930',
          borderRadius: theme.spacing(0.625),
          minHeight: 300,
          width: '100%',
          [theme.breakpoints.down('md')]: {
            minHeight: 270,
          },
        }
        : {
          display: 'flex',
          backgroundColor: colors.white,
          boxShadow: '0px 3px 6px #00002930',
          borderRadius: theme.spacing(0.625),
          alignItems: 'center',
          minHeight: 275,
          [theme.breakpoints.down('md')]: {
            display: 'block',
          },
        }
      }
    >
      {mediaURL && (
        <div
          css={
            layoutType === 'vertical'
              ? {
                lineHeight: theme.spacing(0),
              }
              : {
                lineHeight: theme.spacing(0),
                width: '50%',
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              }
          }
        >
          {mediaType === 'image' && (
            // @ts-ignore
            <ImageLink link={imageLink?.slug || ''} text={header}>
              <img
                css={
                  layoutType === 'vertical' ? {
                    width: '100%',
                    borderRadius: theme.spacing(0.625, 0.625, 0, 0),
                    height: 275,
                    objectFit: 'cover',
                    objectPosition: imagePosition,
                    [theme.breakpoints.down('md')]: {
                      height: 135,
                    },
                  } : {
                    width: '100%',
                    borderRadius: theme.spacing(0.625, 0, 0, 0.625),
                    height: 275,
                    objectFit: 'cover',
                    objectPosition: imagePosition,
                    [theme.breakpoints.down('md')]: {
                      height: 135,
                    },
                  }
                }
                src={mediaURL}
                alt=""
                height="275"
                width="500"
              />
            </ImageLink>
          )}
          {mediaType === 'video' && (
            <div css={{
              '& > iframe': {
                width: '100%',
                height: 275,
                borderRadius: 5,
                [theme.breakpoints.down('md')]: {
                  height: 135,
                },
              },
            }}>
              <PgeVideoPlayer
                video={{
                  url: `${mediaURL}`,
                  ...vimeoProps,
                }}
              />
            </div>
          )}
        </div>
      )}
      <div
        css={
          layoutType === 'vertical'
            ? {
              padding: theme.spacing(3.75, 3.75, 3.125, 3.75),
              [theme.breakpoints.down('md')]: {
                padding: theme.spacing(2.5, 2.5, 1.875, 2.5),
              },
            }
            : {
              padding: theme.spacing(3.75, 5),
              width: '50%',
              [theme.breakpoints.down('md')]: {
                width: '100%',
                padding: theme.spacing(2.5, 2.5, 3.75, 2.5),
              },
            }
        }
      >
        {header && (
          <h3 css={{
            fontSize: theme.spacing(3),
            fontWeight: theme.spacing(12.5),
            lineHeight: theme.typography.pxToRem(28),

            [theme.breakpoints.down('md')]: {
              fontSize: theme.spacing(2.5),
              lineHeight: theme.typography.pxToRem(24),
            },

            color: link ? colors.sparkBlue : colors.noirBlur,
          }}>
            <PgeButton link={link} theme="Text" display="Inline">
              {header}
            </PgeButton>
          </h3>
        )}
        {description && (
          <div css={{
            fontSize: theme.spacing(2.5),
            color: colors.noirBlur,
            [theme.breakpoints.down('md')]: {
              fontSize: theme.spacing(2),
            },
            wordBreak: 'break-word',
          }}>{description}</div>
        )}
        {mediaURL && vimeoDownloadUrl && (
          <div css={{
            textAlign: 'center',
          }}>
            <Button
              component={'a'}
              color="primary"
              size="large"
              href={vimeoDownloadUrl}
              target="_blank"
              rel="noopener noreferrer"
              css={{
                minWidth: '9ch',
                padding: theme.spacing(1, 3),
                boxSizing: 'border-box',
                fontSize: theme.spacing(2),
                borderRight: '1px solid currentColor',
                borderRadius: 0,

                '&:last-child': {
                  borderRight: 'none',
                },

                [theme.breakpoints.down('md')]: {
                  padding: theme.spacing(1, 0),

                  '& .MuiButton-label': {
                    flexDirection: 'column-reverse',
                  },

                  '& .MuiButton-endIcon': {
                    marginLeft: theme.spacing(0),
                  },
                },
              }}
              endIcon={<GetApp />}
            >
              {t('DOWNLOAD')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PromoItem;
